<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">行动列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<router-link :to="{name:'action/add'}">
						<el-button type="primary" size="mini">添加行动</el-button>
					</router-link>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="120" prop="action_id" label="行动ID"></el-table-column>
				<el-table-column align="center" label="单位">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.first_unit_name}}</span>
						<template v-if="scope.row.second_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.second_unit_name}}</span>
						</template>
						<template v-if="scope.row.third_unit_name != ''">
							<span style="font-weight: 800;">- {{scope.row.third_unit_name}}</span>
						</template>
					</template>
				</el-table-column>
				<el-table-column align="center" label="行动标题">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.action_title}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="封面" width="90">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.image">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center" label="行动排序">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.action_sort}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="action_status" label="是否显示">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.action_status" :active-value="10"
							@change="handleStatus(scope.row)" :inactive-value="20"></el-switch>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作">
					<template slot-scope="scope">
						<router-link
							:to="{name:'action/edit',params: {action_id:scope.row.action_id}}">
							<el-button type="text" size="mini" class="table-btn">修改</el-button>
						</router-link>
						</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				lists: [],
				search: {
					s: 'store/action/index',
					page: 1,
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			handleStatus(column) {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/action/state',
						action_id: column.action_id,
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					if (res.data.code === 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
					} else {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定'
						})
					}
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该行动吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/action/delete',
							action_id: data.action_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
